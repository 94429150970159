// @ts-nocheck
import jwt_decode from 'jwt-decode';

export class TokenService {
  JWT_TOKEN = 'ibplayerprojwt';
  JWT_TOKEN_REFRESH = 'ibplayerprojwtrefresh';
  TRANSFER_MASTER = 'ibopro:transferMaster';

  getLocalAccessToken = () => {
    return localStorage.getItem(this.JWT_TOKEN);
  };

  setLocalAccessToken = (jwt) => {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  };

  getLocalRefreshToken = () => {
    return localStorage.getItem(this.JWT_TOKEN_REFRESH);
  };

  setLocalRefreshToken = (refreshToken) => {
    localStorage.setItem(this.JWT_TOKEN_REFRESH, refreshToken);
  };

  getUser = () => {
    const accessToken = this.getLocalAccessToken();
    const refreshToken = this.getLocalRefreshToken();
    return { accessToken, refreshToken };
  };

  setUser = (user) => {
    this.setLocalAccessToken(user.accessToken);
    this.setLocalRefreshToken(user.refreshToken);
  };

  removeUser = () => {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.JWT_TOKEN_REFRESH);
  };

  parseAccessToken = () => {
    try {
      const strToken = this.getLocalAccessToken();
      if (!strToken) throw new Error('No token found!');
      const token = jwt_decode(strToken);
      const now = Math.floor(Date.now() / 1000);
      if (token?.exp < now) throw new Error('Token expired');
      return token;
    } catch (err) {
      return false;
    }
  };

  getLocalLogin = () => {
    const token = this.parseAccessToken();
    return !!token;
  };

  getTransferMaster = () => {
    try {
      return localStorage.getItem(this.TRANSFER_MASTER);
    } catch (e) {
      return '';
    }
  };

  setTransferMaster = (username) => {
    localStorage.setItem(this.TRANSFER_MASTER, username);
  };
}

export const tokenService = new TokenService();

export default tokenService;
